import { observable } from 'mobx'
import {
  createModelSchema,
  primitive,
  date,
} from "serializr"

class Photo {
  @observable image
  @observable imageType
  @observable status
  @observable createdAt

  constructor()
  {
    this.createdAt = new Date()
  }
}

createModelSchema(Photo, {
  image: primitive(),
  imageType: primitive(),
  status: primitive(),
  createdAt: date()
})

export default Photo
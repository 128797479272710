import { observable } from 'mobx'
import {
  createModelSchema,
  primitive,
  date,
  list,
  object,
  identifier,
  serialize,
  deserialize
} from "serializr"

import Location from './Location'
import Photo from './Photo'

class HarvestYield {
  @observable weight
  @observable pollination
  @observable fruitCount
}

class HarvestQuality {
  @observable ripeness
  @observable defects = []
}

class Harvest {
  @observable id
  @observable remoteId
  @observable location = new Location()
  @observable yield = new HarvestYield()
  @observable quality = new HarvestQuality()
  @observable photos = []
  @observable deleted = false
  @observable syncState = 0
  @observable created

  constructor()
  {
    this.created = new Date()
  }

  toJS()
  {
    return serialize(this)
  }

  static fromJS(js)
  {
    return deserialize(Harvest, js)
  }
}

createModelSchema(HarvestYield, {
  weight: primitive(),
  pollination: primitive(),
  fruitCount: primitive(),
})

createModelSchema(HarvestQuality, {
  ripeness: primitive(),
  defects: list(primitive()),
})

createModelSchema(Harvest, {
  id: identifier(),
  remoteId: primitive(),
  location: object(Location),
  yield: object(HarvestYield),
  quality: object(HarvestQuality),
  photos: list(object(Photo)),
  created: date()
})

export default Harvest
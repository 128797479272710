import React from 'react'
import {Grid, Card, CardMedia, CardActionArea, Box, CardContent, Typography, CircularProgress } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import AddPhotoIcon from '@material-ui/icons/AddAPhoto'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { observer } from 'mobx-react'
import store from '../store/Store'
import Photo from '../store/Photo'
import { longDate } from '../util'

function PhotoCard(photo)
{
  const desc = []
  if (photo.imageType)
    desc.push(photo.imageType)
  if (photo.status)
    desc.push(photo.status)
  if (desc.length == 0)
    desc.push('No label')
  // for (let i = 1; i < desc.length; i++)
  //   desc[i] = desc[i].toLowerCase()

  const handleClick = () => {
    const basePath = window.location.hash.split('/').slice(1, 3)
    switch (basePath[0]) {
      case 'grow':
      {
        store.currentPhoto = store.currentGrow.photos[photo.id]
        break
      }
      case 'harvest':
      {
        store.currentPhoto = store.currentHarvest.photos[photo.id]
        break
      }
    }
    window.location.href = '#/' + basePath.join('/') + '/photo/' + photo.id
  }

  return (
    <Grid item key={'add'} style={{width: '33.3%'}}>
    <Card button={true} elevation={3} style={{minHeight: 'calc(16vw + 87.5px)'}}>
      <CardActionArea onClick={() => handleClick()}>
        {/* <CardMedia image={photo.image} style={{height: 140}}/> */}
        <CardMedia>
          <div style={{width: '100%', height: '16vw', backgroundImage: 'url(' + photo.image + ')', backgroundSize: 'cover', backgroundPosition: '50% 50%'}}/>
          {/* <img src={photo.image} style={{width: '100%', maxHeight: 140}}/> */}
        </CardMedia>
        <CardContent style={{minHeight: 55, display: 'flex'}}>
          <Box display="flex" flexDirection="row" alignItems="center" style={{flex: 1}}>
            <Box display="flex" flexDirection="column">
              {/* <Typography gutterBottom variant="h6" component="h6" style={{marginBottom: '0.175em'}}> */}
              <Typography gutterBottom variant="h6" component="h6" style={{marginBottom: '0'}}>
                {/* {desc.join(', ')} */}
                {desc[0]}
              </Typography>
              {desc.length > 1 
                ? (<Typography variant="body2" color="textSecondary" component="p">{desc[1]}{/* {longDate(photo.createdAt)} */}</Typography>)
                : (<React.Fragment/>)
              }
            </Box>
            <Box flex={1}/>
            <Box ml={1}><ChevronRightIcon/></Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid>
  )
}

class PhotoListInline extends React.Component
{
  handleAdd()
  {
    const numPhotos = this.props.target.photos ? this.props.target.photos.length : 0
    const maxPhotos = 15
    if (numPhotos >= maxPhotos)
    {
      alert(`An observation cannot have more than ${maxPhotos} photos.`)
      return
    }

    store.currentPhoto = new Photo()
    const loc1 = window.location + '/photo/new'
    const loc2 = window.location + '/photo/new/capture'
    // window.location.href = loc1
    window.location.href = loc1
    setTimeout(() => window.location.href = loc2, 0)
  }

  render()
  {
    const items = []

    // add button
    items.push(
      <Grid item key={'add'} style={{width: '33.3%'}}>
        <Card button={true} elevation={3}>
          <CardActionArea onClick={() => this.handleAdd()}>
            {/* <CardMedia style={{height: 227.5}}> */}
            <CardMedia style={{height: 'calc(16vw + 87.5px)'}}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
                <AddPhotoIcon style={{fontSize: 64, opacity: 0.35}}/>
                <Typography gutterBottom variant="button" component="h2" style={{opacity: 0.35}}>Add a Photo</Typography>
              </div>
            </CardMedia>
            {/* <CardContent>
            </CardContent> */}
          </CardActionArea>
        </Card>
      </Grid>
    )      

    let i = 0
    for (const photo of this.props.target.photos)
    {
      items.push(<PhotoCard id={i} key={i} {...photo}/>)
      i++
    }

    items.reverse()
    
    return (
      // <Box mt={3} mb={3}>
      <Grid 
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
        >
        {items}
      </Grid>
      // </Box>
    )
  }
}

export default observer(PhotoListInline)

import React from 'react'
import Screen from './Screen'
import { observer } from 'mobx-react'
import HarvestForm from './HarvestForm'

class HarvestFormScreen extends Screen
{
  renderContent()
  {
    return (
      <div style={{display: 'flex', 'flexDirection': 'column', height: '100%'}}>
        <div style={{flex: 1, 'overflowY': 'scroll', 'WebkitOverflowScrolling': 'touch'}}>
          <HarvestForm/>
        </div>
      </div>
    )
  }
}

export default observer(HarvestFormScreen)
import React from 'react'
import {Grid, Card, CardMedia, CardActionArea, Box, CardContent, Typography, CircularProgress } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { observer } from 'mobx-react'

class PhotoList extends React.Component
{
  render()
  {
    const ims = ['/plant.jpg', '/plant2.jpg', '/plant3.jpg', '/plant4.jpg']
    const descs = ['Plant, healthy', 'Fruit, healthy', 'Leaf, diseased', 'Leaf, healthy', 'Fruit, damaged']

    const items = []
    for (let i = 0; i < 20; i++)
    {
      const icon = i < 4 ? null : (
        i === 4 ?
          <CircularProgress size={20} thickness={4}/> :
          <CheckIcon fontSize='small' />
      )
      items.push(
        <Grid item key={i} style={{width: '30%'}}>
          <Card button elevation={3}>
            <CardActionArea onClick={() => window.location.href = '#/photos/1'}>
              {/* <CardHeader title={descs[i % descs.length]} subheader="3 April 2020, 16:20" action={
                <IconButton aria-label="settings">
                  <ChevronRightIcon />
                </IconButton>
              }/> */}
              <CardMedia image={ims[i % ims.length]} style={{height: 140}}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {descs[i % descs.length]}
                </Typography>
                <Box display="flex" flexDirection="row">
                  <Typography variant="body2" color="textSecondary" component="p">
                    3 April 2020, 16:20
                  </Typography>
                  <Box flex={1}/>
                  <Box ml={1}>{icon}</Box>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )      
      // items.push( <Divider/>)
    }
    
    return (
      <Box mt={3} mb={3}>
      <Grid 
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}>
        {items}
      </Grid>
      </Box>
    )
  }
}

export default observer(PhotoList)

import { observable } from 'mobx'
import {
  createModelSchema,
  primitive,
  date,
  list,
  object,
  identifier,
  serialize,
  deserialize
} from "serializr"

import Location from './Location'
import Photo from './Photo'

class GrowPlant {
  @observable weekOfGrow
  @observable heightDelta
  @observable stemDiameter
  @observable flowerClusters
  @observable fruitCount
}

class GrowLeaves {
  @observable count
  @observable averageWidth
  @observable averageLength
}

class GrowMedium {
  @observable substrate
  @observable ph
  @observable ec
  @observable volumeIn
  @observable volumeOut
}

class Grow {
  @observable id
  @observable remoteId
  @observable location = new Location()
  @observable plant = new GrowPlant()
  @observable leaves = new GrowLeaves()
  @observable medium = new GrowMedium()
  @observable photos = []
  @observable deleted = false
  @observable syncState = 0
  @observable created

  constructor()
  {
    this.created = new Date()
  }

  toJS()
  {
    return serialize(this)
  }

  static fromJS(js)
  {
    return deserialize(Grow, js)
  }
}

createModelSchema(GrowPlant, {
  weekOfGrow: primitive(),
  heightDelta: primitive(),
  stemDiameter: primitive(),
  flowerClusters: primitive(),
  fruitCount: primitive()
})

createModelSchema(GrowLeaves, {
  count: primitive(),
  averageWidth: primitive(),
  averageLength: primitive(),
})


createModelSchema(GrowMedium, {
  substrate: primitive(),
  ph: primitive(),
  ec: primitive(),
  volumeIn: primitive(),
  volumeOut: primitive(),
})

createModelSchema(Grow, {
  id: identifier(),
  remoteId: primitive(),
  location: object(Location),
  plant: object(GrowPlant),
  leaves: object(GrowLeaves),
  medium: object(GrowMedium),
  photos: list(object(Photo)),
  created: date()
})

export default Grow
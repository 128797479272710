import React from 'react'
import Screen from './Screen'
import { observer } from 'mobx-react'
import PhotoForm from './PhotoForm'

class PhotoFormScreen extends Screen
{
  renderContent()
  {
    return (
      <div style={{display: 'flex', 'flex-direction': 'column', height: '100%'}}>
        <div style={{flex: 1, 'overflow-y': 'scroll', '-webkit-overflow-scrolling': 'touch'}}>
          <PhotoForm/>
        </div>
      </div>
    )
  }
}

export default observer(PhotoFormScreen)
import React from 'react'
import Screen from './Screen'
import { observer } from 'mobx-react'
import GrowForm from './GrowForm'

class GrowFormScreen extends Screen
{
  renderContent()
  {
    return (
      <div style={{display: 'flex', 'flexDirection': 'column', height: '100%'}} key={0}>
        <div style={{flex: 1, 'overflowY': 'scroll', 'WebkitOverflowScrolling': 'touch'}}>
          <GrowForm/>
        </div>
      </div>
    )
  }
}

export default observer(GrowFormScreen)
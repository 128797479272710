import React from 'react'
import {CircularProgress} from '@material-ui/core'
// import {observer} from 'mobx-react'

export default class Screen extends React.Component
{
  state = {
    ready: false
  }

  render()
  {
    const content = this.state.ready ? this.renderContent() : [this.renderContent(), this.renderSpinner()]
    return <div className='screen'>{content}</div>
  }

  renderSpinner()
  {
    return <div className="loading-spinner" key={99}><CircularProgress color="secondary"/></div>
  }

  renderContent()
  {
    return '[override me]'
  }

  onEnter() { 
    this.setState({ready: true})
    // setTimeout(() => this.setState({ready: true}), 500)
  }

  onExit() { this.setState({ready: false})}
}
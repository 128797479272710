import React from 'react'
import { AppBar, Toolbar, IconButton, Typography, ThemeProvider, Drawer, Snackbar, CircularProgress } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import MenuIcon from '@material-ui/icons/Menu'
import BackIcon from '@material-ui/icons/ArrowBack'
import { Add } from '@material-ui/icons'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import Menu from './components/Menu'
import HomeScreen from './components/HomeScreen'
import HarvestFormScreen from './components/HarvestFormScreen'
import GrowFormScreen from './components/GrowFormScreen'
import PhotoFormScreen from './components/PhotoFormScreen'
import PhotoCaptureScreen from './components/PhotoCaptureScreen'
import ChangePasswordScreen from './components/ChangePasswordScreen'
import SignIn from './components/SignIn'
import store from './store/Store'
import { observer } from 'mobx-react'

const drawerWidth = 240
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#18D2AB',
      contrastText: '#0A1018',
    },
    secondary: {
      main: '#1A2030',
      contrastText: '#18D2AB',
    }
  }
})

const styles = theme_ => ({
  appBar: {
  },
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.primary.dark,
  },
  drawerPaper: { width: drawerWidth },
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props}  style={{backgroundColor: '#fff'}}/>;
}

class App extends React.Component
{
  screenRef = null

  state = {
    // route: [],
    navOpen: false,
    screen: null,
    snackOpen: false,
  }

  constructor(props) 
  {
    super(props)
    window.addEventListener('hashchange', this.handleHashChange.bind(this))
  }

  componentDidMount()
  {
    this.handleHashChange()
    this.forceUpdate()
  }

  signOut() 
  {
    this.setState({navOpen: false}) 
    store.credentials.authStatus = 'signedOut'
    // this.handleHashChange()
    // this.forceUpdate()
    // store.signedIn = false
  }

  async signIn()
  {
    // store.signedIn = true
    await store.credentials.signIn()
    this.handleHashChange()
    this.forceUpdate()
  }

  changePassword()
  {
    this.setState({navOpen: false})
    window.location.href = '#/change-password'
  }

  async handleChangePassword(newPassword)
  {
    // alert('newpassword ' + newPassword)
    await store.credentials.changePassword(newPassword)
    this.handleHashChange()
    this.forceUpdate()
    this.showSnack()
  }

  showSnack()
  {
    setTimeout(() => {
      this.setState({snackOpen: true})
    }, 200)
  }

  handleAdd()
  {
    switch (store.route[1])
    {
      case 'grow': return window.location.href = '#/grow/new'
      case 'harvest': return window.location.href = '#/harvest/new'
      case 'photos': return window.location.href = '#/photos/new/capture'
      default: return
    }
  }

  handleSnackClose()
  {
    this.setState({snackOpen: false})
  }

  async handleHashChange()
  {
    // console.log('hash change', window.location.hash)

    let route = window.location.hash.substr(1)
    if (route.length > 0 && route.charAt(0) === '/')
      route = route.substr(1)
    if (route.length > 0 && route.charAt(route.length - 1) === '/')
      route = route.substr(0, route.length - 1)
    if (route === '')
      route = 'home/grow'
    route = route.split('/')
    if (route.join('/') !== store.route.join('/'))
    {
      if (this.screenRef)
      this.screenRef.onExit()
      store.route = route
    }


    let screen = null
    const screenProps = {ref: (ref) => this.screenRef = ref}
    if (store.route.length >= 3)
    {
      switch (store.route[0])
      {
        case 'harvest':
        case 'grow':
        {
          if (store.route[2] === 'photo')
          {
            if (store.route[4] === 'capture')
              screen = <PhotoCaptureScreen {...screenProps}/>
            else
              screen = <PhotoFormScreen {...screenProps}/>
        }

          break  
        }
        default:
        {
          break
        }

      }

    }
    if (!screen && store.route.length >= 1)
    {
      switch (store.route[0])
      {
        case 'home':
        {
          screen = <HomeScreen {...screenProps}/>
          break
        }
        case 'harvest':
        {
          screen = <HarvestFormScreen {...screenProps}/>
          break
        }
        case 'grow':
        {
          screen = <GrowFormScreen {...screenProps}/>          
          break
        }
        case 'photos':
        {
          screen = <PhotoFormScreen {...screenProps}/>
          if (store.route.length >= 2 && store.route[2] === 'capture')
            screen = <PhotoCaptureScreen {...screenProps}/>
          break;
        }
        case 'change-password':
        {
          screen = <ChangePasswordScreen onChangePassword={this.handleChangePassword.bind(this)} {...screenProps}/>
          break
        }
        default:
        {
          break
        }
      }
    }
    await this.setState({screen})

    if (this.screenRef)
    this.screenRef.onEnter()

    this.updateNavigation()
  }

  updateNavigation()
  {
    // console.log('update navigation', store.route)
  }

  render()
  {
    // console.log('store.signedIn', store.signedIn)

    // if (!store.signedIn)
    if (store.credentials.authStatus === 'signedOut')
      return <ThemeProvider theme={theme}><SignIn store={store} onSignIn={() => this.signIn()}/></ThemeProvider>
    
    if (store.credentials.authStatus === 'newPasswordRequired')
      return <ThemeProvider theme={theme}><ChangePasswordScreen modal={true} onChangePassword={this.handleChangePassword.bind(this)} /></ThemeProvider>
    
    if (store.credentials.authStatus === 'signingIn' || store.credentials.authStatus === 'changingPassword')
      return (
        <ThemeProvider theme={theme}>
          <div className="loading-spinner" key={99}><CircularProgress color="secondary"/></div>
        </ThemeProvider>
      )

    const { classes } = this.props
    const appNav = Menu({onSignOut: this.signOut.bind(this), onChangePassword: this.changePassword.bind(this), classes})
    const screen = this.state.screen

    const route = store.route
    let title = "Cortex MIA"
    if (route.length > 2)
    {
      if (route[2] === 'photo')
      {
        if (route.length > 3 && route[4] === 'capture')
        {
          title = 'Capture Photo'          
        }
        else if (route[3] === 'new')
          title = 'New Photo'
        else
          title = 'Edit Photo'
      }
    }
    else if (route.length > 0)
    {
      switch (route[0]) {
        case 'grow':
        {
          title = route[1] === 'new' ? 'New Grow Observation' : 'Edit Grow Observation' 
          break;
        }
        case 'harvest':
        {
          title = route[1] === 'new' ? 'New Harvest Observation' : 'Edit Harvest Observation' 
          break;
        }
        case 'photos':
        {
          title = route[1] === 'new' ? 'New Photo' : 'Edit Photo' 
          if (route.length > 2 && route[2] === 'capture')
            title = 'Capture Photo'
          break;
        }
        case 'change-password':
        {
          title = 'Change password'
          break
        }
        default: { break }
      }
    }

    let addButton = <IconButton color="inherit" onClick={() => this.handleAdd()}><Add /></IconButton>
    if (route.length && route[0] !== 'home')
      addButton = null

    let menuIcon = <MenuIcon/>
    let menuHandler = () => this.setState({navOpen: true})
    if (route.length === 0 || route[0] !== 'home')
    {
      menuIcon = <BackIcon/>
      menuHandler = () => {
        if (this.screenRef && this.screenRef.back)
          this.screenRef.back()
        else
          window.history.back()
      }
    }

    return (
      <div className="App">
        <ThemeProvider theme={theme}>
            
          {/* APP BAR */}
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" style={{marginRight: theme.spacing(2)}} className={classes.menuButton}  onClick={menuHandler}>
                {menuIcon}
              </IconButton>
              <Typography variant="h6" style={{flexGrow: 1, textAlign: 'left'}}>
                {title}
              </Typography>
              {addButton}
            </Toolbar>
          </AppBar>      
  
          {/* NAV */}
          <nav className={classes.drawerPaper}>
            <Drawer 
              variant="temporary"
              open={this.state.navOpen}
              onClose={() => this.setState({navOpen: false})}
              ModalProps={{keepMounted: true}}
              // classes={[classes.drawerPaper]}
            >
              {appNav}
            </Drawer>
          </nav>
          
          {screen}

          <Snackbar open={this.state.snackOpen} autoHideDuration={4000} onClose={this.handleSnackClose.bind(this)}>
            <Alert onClose={this.handleSnackClose.bind(this)} severity="success">
              Your password has been changed.
            </Alert>
          </Snackbar>

        </ThemeProvider>

      </div>
    );    

  }
}

export default withStyles(styles)(observer(App));

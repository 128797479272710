import React from 'react'
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton, Divider, CircularProgress, Typography, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/ChevronRight'
import CheckIcon from '@material-ui/icons/Check'
import PlusIcon from '@material-ui/icons/Add'
import DataService from '../DataService'
import { observer } from 'mobx-react'
import { longDate } from '../util'

class GrowList extends React.Component
{
  state = {data: null}

  async fetchData()
  {
    this.setState({data: await DataService.db.getAll('grow')})
  }

  refresh()
  {
    this.fetchData()
  }

  render()
  {
    if (!this.state.data)
      return <div/>
      
    if (this.state.data.length === 0)
      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
          <Typography variant="subtitle1" component="h3" style={{marginBottom: '0.5em'}}>Tap the + to add a grow observation</Typography>
          {/* <Typography variant="subtitle1" component="h3" style={{marginBottom: '0.5em'}}>Nothing here yet</Typography>
          <Button variant="outlined" color="secondary" size="large" startIcon={<PlusIcon/>} onClick={() => window.location.href='#/grow/new'}>Add Observation</Button> */}
        </div>
      )

    // alert(this.state.data)

    const items = []
    const rows = this.state.data
      .sort((a, b) => b.created - a.created)
      .filter((v) => !v.deleted)

    for (let i = 0; i < rows.length; i++)
    {
      const row = rows[i]
      let icon = [
        <div/>,
        <CircularProgress size={24} thickness={4}/>,
        <CheckIcon/>
      ][row.syncState || 0]
      
      let loc = []
      if (row.location.zone)
        loc.push('Zone ' + row.location.zone)
      if (row.location.row)
        loc.push('Row ' + row.location.row)
      if (row.location.section)
        loc.push('Section ' + row.location.section)
      if (row.location.cluster)
        loc.push('Cluster ' + row.location.cluster)
      loc = loc.length ? loc.join(', ') : 'Unspecified location'

      // const created = moment(row.created).format('D MMMM YYYY, h:mm:ss a');
      let created = longDate(row.created)
      if (row.photos.length > 0)
        created += ' · ' + row.photos.length + ' photo' + (row.photos.length > 1 ? 's' : '')

      items.push(
        <ListItem button key={row.id} onClick={() => window.location.href="#/grow/" + row.id}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={loc} secondary={created}></ListItemText>
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )      
      items.push( <Divider key={row.id + '_d'}/>)
    }
    
    return <List>{items}</List>
  }

}

export default observer(GrowList)

import React from 'react'
import { Divider, List, ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core'
import { VpnKey, ExitToApp } from '@material-ui/icons'
// import { observer } from 'mobx-react'

function Menu(props)
{
  const {classes, onSignOut, onChangePassword} = props
  return (
    <div className={classes.drawerPaper}>
      <Box className={classes.toolbar} boxShadow={5}>
        {/* <img src="logo-thc.png" width={drawerWidth} height={drawerWidth * 576 / 1024}/> */}
      </Box>
      <Divider />
      <List>
        {[
          // ['Change password', VpnKey, onChangePassword], 
          ['Sign out', ExitToApp, onSignOut]
        ].map((item, index) => (
          <div key={item[0]}>
            <ListItem button onClick={item[2]}>
              <ListItemIcon>{React.createElement(item[1], {})}</ListItemIcon>              
              <ListItemText primary={item[0]} />
            </ListItem>
            {/* {item[0] == 'Dashboard' ? <Divider/> : null} */}
          </div>
        ))}
      </List>
    </div>
  )
}

export default Menu
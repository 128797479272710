import { observable } from 'mobx'
import {
  createModelSchema,
  primitive,
} from "serializr"

class Location {
  @observable zone
  @observable row
  @observable section
  @observable cluster
}

createModelSchema(Location, {
  zone: primitive(),
  row: primitive(),
  section: primitive(),
  cluster: primitive(),  
})

export default Location
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { /*createMuiTheme, makeStyles, */withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {observer} from 'mobx-react'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

class SignIn extends React.Component
{
  render()
  {
    const { classes, store } = this.props;
    const creds = store.credentials

    // console.log('CREDS', creds)

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h4">
            Cortex MIA
          </Typography>
          <Box mt={8}/>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <form className={classes.form} noValidate>
            <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus 
              value={creds.username || ''} onChange={e => creds.username = e.currentTarget.value}/>
            <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" 
              value={creds.password || ''} onChange={e => creds.password = e.currentTarget.value}/>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={() => {
                // store.credentials.signIn()
                this.props.onSignIn()
              }}
            >
              Sign In
            </Button>

          </form>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(observer(SignIn))
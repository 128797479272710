import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dataService from './DataService'
import syncService from './SyncService'

const startup = async () => {
  window.syncService = syncService
  await dataService.init()
  ReactDOM.render(<App />, document.getElementById('root'));
}

startup()
serviceWorker.unregister()

import React from 'react'
import { Typography, TextField, InputAdornment, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import store from '../store/Store'
import Grow from '../store/Grow'
import PhotoListInline from './PhotoListInline'
import dataService from '../DataService'
import { observer } from 'mobx-react'
import { longDate } from '../util'

function Heading(props)
{
  return <Typography variant='h5'>{props.children}</Typography>
}

export default observer(class GrowForm extends React.Component
{
  async componentDidMount()
  {
    const isEditing = store.route[1] !== 'new'
    if (!store.currentGrow)
    {
      if (isEditing)
        store.currentGrow = Grow.fromJS(await dataService.db.get('grow', parseInt(store.route[1])))
      else
        store.currentGrow = new Grow()
    }
  }

  async handleCancel()
  {
    // window.location.href='#/home/grow'
    window.history.back()
  }

  async handleDelete()
  {
    if (window.confirm('Are you sure you want to delete this observation?'))
      this.handleSave(true)
  }
  
  async handleSave(deleteRow=false)
  {
    // console.log('saving', store.currentGrow.toJS())
    const row = store.currentGrow.toJS()
    row.syncState = 0
    row.deleted = deleteRow
    let id = row.id    
    if (id)
    {
      await dataService.db.put('grow', row)
    }
    else    
    {
      delete row.id
      id = await dataService.db.put('grow', row)
    }
    // console.log('saved, id: ' + id)
    // window.location.href='#/home/grow'
    window.history.back()
  }

  render()
  {
    // if (!this.state.ready)
    if (!store.currentGrow)
      return <div/>

    const isEditing = store.route[1] !== 'new'
    const row = store.currentGrow   
    const makeEditProps = (parent, propName) => {
      return {value: parent[propName] || '', onChange: (e) => parent[propName] = e.target.value}
    }
    return (
      <form  noValidate autoComplete="off">
        <div className="form">
          
          {isEditing ? <section>
            <Heading>Captured</Heading>
            <Typography>{longDate(row.created)}</Typography>
          </section> : null}

          <section>
            <Heading>Location</Heading>
            <div className="col3">
              <div className="field"><TextField label="Zone" variant="outlined" type="number" {...makeEditProps(row.location, 'zone')}/></div>
              <div className="field"><TextField label="Row" variant="outlined" type="number" {...makeEditProps(row.location, 'row')}/></div>
              <div className="field"><TextField label="Section" variant="outlined" type="number" {...makeEditProps(row.location, 'section')}/></div>
            </div>
          </section>

          <div className="cols">
            <section>
              <Heading>Plant</Heading>
              <div>
                <div className="field"><TextField label="Grow week" variant="outlined" type="number" {...makeEditProps(row.plant, 'weekOfGrow')}/></div>
                <div className="field"><TextField label="Height delta" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">mm</InputAdornment>}} {...makeEditProps(row.plant, 'heightDelta')}/></div>
                <div className="field"><TextField label="Stem diameter" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">mm</InputAdornment>}} {...makeEditProps(row.plant, 'stemDiameter')}/></div>
                <div className="field"><TextField label="Flower clusters" variant="outlined" type="number" {...makeEditProps(row.plant, 'flowerClusters')}/></div>
                <div className="field"><TextField label="Fruit count" variant="outlined" type="number" {...makeEditProps(row.plant, 'fruitCount')}/></div>
              </div>
            </section>

            <section>
              <Heading>Leaves</Heading>
              <div>
                <div className="field"><TextField label="Count" variant="outlined" type="number" {...makeEditProps(row.leaves, 'count')}/></div>
                <div className="field"><TextField label="Avg. length" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">mm</InputAdornment>}} {...makeEditProps(row.leaves, 'averageLength')}/></div>
                <div className="field"><TextField label="Avg. width" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">mm</InputAdornment>}} {...makeEditProps(row.leaves, 'averageWidth')}/></div>
              </div>
            </section>

            <section>
              <Heading>Medium</Heading>
              <div>
                <div className="field"><TextField label="Substrate" variant="outlined" {...makeEditProps(row.medium, 'substrate')}/></div>
                <div className="field"><TextField label="PH" variant="outlined" type="number" {...makeEditProps(row.medium, 'ph')}/></div>
                <div className="field"><TextField label="EC" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">mS/cm</InputAdornment>}} {...makeEditProps(row.medium, 'ec')}/></div>
                <div className="field"><TextField label="Input volume" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">ml</InputAdornment>}} {...makeEditProps(row.medium, 'volumeIn')}/></div>
                <div className="field"><TextField label="Output volume" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">ml</InputAdornment>}} {...makeEditProps(row.medium, 'volumeOut')}/></div>
              </div>
            </section>
          </div>

          <section>
            <Heading>Photos</Heading>
            <div>
              <PhotoListInline target={row}/>
            </div>
          </section>

          <div className="buttons">
            <Button variant="contained" color="primary" size="large" startIcon={<SaveIcon/>} onClick={() => this.handleSave()}>Save</Button>
            {isEditing ? <Button variant="contained" size="large" startIcon={<DeleteIcon/>} onClick={() => this.handleDelete()}>Delete</Button> : null}
            <Button size="large" variant="outlined" onClick={() => window.history.back()}>Cancel</Button>
          </div>

        </div>
      </form>
    )
  }
})
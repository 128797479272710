import React from 'react'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import Screen from './Screen'
import { observer } from 'mobx-react'
import ChangePasswordForm from './ChangePasswordForm'

class ChangePasswordScreen extends Screen
{
  state = {ready: true}

  renderAppBar()
  {
    return (
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" style={{flexGrow: 1, textAlign: 'left'}}>
            Change Password
          </Typography>
        </Toolbar>
      </AppBar>        
    )  
    }

  renderContent()
  {
    const inner = (
      <div style={{display: 'flex', 'flex-direction': 'column', height: '100%'}}>
        <div style={{flex: 1, 'overflow-y': 'scroll', '-webkit-overflow-scrolling': 'touch'}}>
          <ChangePasswordForm onChangePassword={this.props.onChangePassword.bind(this)} modal={this.props.modal}/>
        </div>
      </div>
    )

    if (this.props.modal)
    {
      return (
        <React.Fragment>
          {this.renderAppBar()}
          {inner}
        </React.Fragment>
      )
    }
    else
      return inner

  }
}

export default observer(ChangePasswordScreen)
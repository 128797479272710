import dataService from './DataService'
import axios from 'axios'
import store from './store/Store'
import { resizeImage } from './util'

class SyncService
{
  constructor()
  {
    this.update() 
  }

  async sync(row, storeName)
  {
    row.syncState = 1
    await dataService.db.put(storeName, row)
    if (this.onUpdate) this.onUpdate()

    let method = 'post'
    const r = JSON.parse(JSON.stringify(row)) // clone

    let remoteId = ''

    // clean up for submission
    delete r.id
    delete r.syncState
    if ('remoteId' in r)
    {
      method = r.deleted ? 'delete' : 'put'
      remoteId = r.remoteId
      // r.id = r.remoteId
    }
    delete r.remoteId  
    delete r.deleted
    r.dateTime = new Date(r.created)
    delete r.created
    for (let i in r.photos)
    {
      r.photos[i] = JSON.parse(JSON.stringify(r.photos[i]))
      r.photos[i].image = await resizeImage(r.photos[i].image)
      r.photos[i].image = r.photos[i].image.split(',')[1]
      delete r.photos[i].createdAt
    }

    // window.result = JSON.stringify(r)
    // console.log(method, window.result)

    // FAKE SYNC
    // await new Promise((resolve) => { setTimeout(() => resolve(), 1000 + Math.random() * 2000) })
    
    // console.log(r)

    // START ACTUAL SYNC
    try {
      const res = await axios({
        method,
        headers: {
          authorization: store.credentials.jwt,
          'content-type': 'application/json',
        },
        url: 'https://dev.api.coretex.uk/mia/' + storeName + (remoteId ? '/' + remoteId : ''),
        data: r
      })
      if (method === 'post')
        row.remoteId = res.data.id
    } catch (e) {
      if (e.response && (e.response.status === 401))
      {
        await store.credentials.silentSignIn()
        this.sync(row, storeName)
        return
      }
      else
      {
        alert('Error syncing row.\n' + e)
      }
    }
    // END ACTUAL SYNC

    row.syncState = 2
    await dataService.db.put(storeName, row)
  }

  async update()
  {

    if (dataService.ready)
    {
      for (const storeName of ['grow', 'harvest'])
      {
        const rows = await dataService.db.getAll(storeName)
        for (const row of rows)
        {
          if (!row.syncState || row.syncState === 1)
          {
            await this.sync(row, storeName)
            break
          }
        }
      }

      if (this.onUpdate) this.onUpdate()
    }
    setTimeout(() => this.update(), 1000)
  }
}

const syncService = new SyncService()
export default syncService
import React from 'react'
import Screen from './Screen'
import store from '../store/Store'
import { Box, Button } from '@material-ui/core'
import CameraIcon from '@material-ui/icons/PhotoCamera'
import { observer } from 'mobx-react'
import { resizeImage } from '../util'

class PhotoCaptureScreen extends Screen
{
  video = null
  settings = null
  resizeHandler = null

  componentDidMount() 
  {
    this.video = document.querySelector('video');
    navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}}).then((stream) => {
      this.stream = stream
      this.settings = stream.getVideoTracks()[0].getSettings()
      this.resize()
      this.video.srcObject = stream
      this.resizeHandler = this.resize.bind(this)
      window.addEventListener('resize', this.resizeHandler)
    });
  }

  componentWillUnmount()
  {
    this.stream.getTracks().forEach(function(track) {
      track.stop();
    });
    window.removeEventListener('resize', this.resizeHandler)
  }

  resize()
  {
    const h = this.settings.height
    const w = this.settings.width      
    const cw = this.video.parentNode.clientWidth
    const ch = this.video.parentNode.clientHeight
    let vw = cw, vh = h * cw / w
    if (vh > ch)
    {
      vh = ch
      vw = w * ch / h
    }
    this.video.width = vw
    this.video.height = vh
    // console.log('resize', vw, vh)
  }

  back(success=false)
  {
    console.log('PHOTOID', store.currentPhoto.id)
    if (success || store.currentPhoto.id !== undefined || store.currentPhoto.image)
      window.history.back()
    else
      window.history.go(-2)
      // window.history.go(store.currentPhoto.id === undefined ? -2 : -1)
      // window.history.go(store.currentPhoto.id === undefined ? -2 : -1)
  }
  
  async finish(success)
  {
    if (success)
    {
      const imageCapture = new ImageCapture(this.stream.getVideoTracks()[0])
      const blob = await imageCapture.takePhoto()
      
      let dataURL
      await new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          dataURL = reader.result
          resolve()
        })
        reader.readAsDataURL(blob)
      })
      
      // console.log(blob)
      // const dataURL = URL.createObjectURL(blob)
      store.currentPhoto.image = await resizeImage(dataURL)
      // console.log(dataURL)



      // TODO: go back if possible
      this.back(true)

      // const newRoute = store.route.slice(0, store.route.length - 1).join('/')
      // window.location.replace('#/' + newRoute)  
    }
    else
      this.back()
  }

  renderContent()
  {
    return (
      <div style={{display: 'flex', 'flex-direction': 'column', height: '100%', background: '#000'}}>
        <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
          <video autoPlay={true}></video>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" style={{backgroundColor: '#111'}} p={2}>
          <Button variant="contained" color="primary" size="large" startIcon={<CameraIcon/>} onClick={() => this.finish(true)}>Capture</Button>
          <Box mr={2}/>
          <Button color="primary" variant="outlined" size="large" onClick={() => this.finish()}>Cancel</Button>
        </Box>

      </div>
    )
  }
}

export default observer(PhotoCaptureScreen)
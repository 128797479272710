import React from 'react'
import { TextField, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/VpnKey';
import CheckIcon from '@material-ui/icons/Check';
import { observer } from 'mobx-react'

// function Heading(props)
// {
//   return <Typography variant='h5'>{props.children}</Typography>
// }

export default observer(class ChangePasswordForm extends React.Component
{
  state = {
    password: '',
    confirmPassword: '',
  } 

  render()
  {
    const disabled = this.state.password.length == 0 || this.state.password !== this.state.confirmPassword
    return (
      <form  noValidate autoComplete="off">
        <div className="form">
          
          {this.props.modal ? <p>Please choose a new password.</p> : null}

          <section>
            <div>
              <div className="field"><TextField label="New password" variant="outlined" type="password" value={this.state.password} onChange={e => this.setState({password: e.currentTarget.value})}/></div>
              <div className="field"><TextField label="Confirm new password" variant="outlined" type="password" value={this.state.confirmPassword} onChange={e => this.setState({confirmPassword: e.currentTarget.value})}/></div>
            </div>
          </section>

          <div className="buttons">
            {this.props.modal
              ? <Button variant="contained" color="primary" size="large" startIcon={<SaveIcon/>} disabled={disabled} onClick={() => {this.props.onChangePassword(this.state.password)}}>Change password</Button>
              : 
                <React.Fragment>
                  <Button variant="contained" color="primary" size="large" startIcon={<SaveIcon/>} disabled={disabled} onClick={() => {this.props.onChangePassword(this.state.password); window.history.back()}}>Change</Button>
                  <Button size="large" variant="outlined" onClick={() => window.history.back()}>Cancel</Button>
                </React.Fragment>
            }
          </div>

        </div>
      </form>
    )
  }
})
import moment from 'moment'

const maxWidth = 1024
const maxHeight = 768
const jpgQuality = 0.8

function longDate(date)
{
  return moment(date).format('D MMMM YYYY, HH:mm') //:ss
}

async function resizeImage(dataURL)
{
  const img = new Image()
  await new Promise((resolve, reject) => {
    img.onload = () => {
      resolve()
    }
    img.src = dataURL
  })
  const sw = img.width
  const sh = img.height
  let w = maxWidth
  let h = w / sw * sh
  if (h > maxHeight)
  {
    h = maxHeight
    w = h / sh * sw
  }
  if (w > sw || h > sh)
  {
    w = sw
    h = sh
  }
  let canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d')
  canvas.width = w
  canvas.height = h
  ctx.drawImage(img, 0, 0, w, h)
  const newDataURL = canvas.toDataURL('image/jpeg', jpgQuality)
  console.log(`resized image (${sw}x${sh}, ${Math.round(dataURL.length / 1024)}kb) to (${w}x${h}, ${Math.round(newDataURL.length / 1024)}kb))`)
  return newDataURL
}

export {
  longDate,
  resizeImage
}
import React from 'react'
import { Tabs, Tab, Paper } from '@material-ui/core'
import store from '../store/Store'
import { observer } from 'mobx-react'

class HomeTabs extends React.Component
{
  render()
  {
    let tabIndex = 0
    if (store.route.length > 1 && store.route[1] === 'harvest')
      tabIndex = 1
    // if (store.route.length > 1 && store.route[1] === 'photos')
    //   tabIndex = 2
    return (
      // <Paper elevation={0} variant="outlined" square style={{borderTop: 'none'}}>
      <Paper elevation={0} square style={{backgroundColor: '#f6f6f6'}}>
        <Tabs variant="fullWidth" indicatorColor="primary" textColor="secondary" value={tabIndex}>
          <Tab label="Grow" onClick={() => window.location.replace('#/home/grow')} key={0}></Tab>
          <Tab label="Harvest" onClick={() => window.location.replace('#/home/harvest')} key={1}></Tab>
          {/* <Tab label="Photos" onClick={() => window.location.replace('#/home/photos')}  key={2}></Tab> */}
        </Tabs>
      </Paper>
    )
  }
}

export default observer(HomeTabs)

import { observable } from 'mobx'
import Credentials from './Credentials'

class Store {
  @observable signedIn = false
  @observable route = []
  // @observable grows = []
  @observable currentGrow
  @observable currentHarvest
  @observable currentPhoto

  @observable credentials = new Credentials()
}

const store = new Store()
window.store = store
export default store
import React from 'react'
import Screen from './Screen'
import store from '../store/Store'
import { observer } from 'mobx-react'
import HomeTabs from './HomeTabs'
import GrowList from './GrowList'
import HarvestList from './HarvestList'
import PhotoList from './PhotoList'
import syncService from '../SyncService'

class HomeScreen extends Screen
{
  content = null

  getContent()
  {
    let props = {ref: (ref) => this.content = ref}
    let listIndex = 0
    if (store.route.length > 1 && store.route[1] === 'harvest')
      listIndex = 1
    if (store.route.length > 1 && store.route[1] === 'photos')
      listIndex = 2
    const list = [<GrowList {...props}/>, <HarvestList {...props}/>, <PhotoList {...props}/>][listIndex]
    return list
  }

  renderContent()
  {
    const list = this.getContent()
    return (
      <div style={{display: 'flex', 'flexDirection': 'column', height: '100%'}} key={0}>
        <HomeTabs/>
        <div style={{flex: 1, 'overflowY': 'scroll', 'WebkitOverflowScrolling': 'touch'}}>
          {list}
        </div>
      </div>
    )
  }

  async onEnter()
  {
    // const list = this.getContent()
    const content = this.content
    if (content instanceof GrowList || content instanceof HarvestList)
      await content.fetchData()
    store.currentGrow = null
    store.currentHarvest = null
    store.currentPhoto = null
    this.setState({ready: true})
    syncService.onUpdate = () => this.refresh()
  }

  onExit()
  {
    syncService.onUpdate = null
  }

  refresh()
  {
    if (this.content && this.content.refresh)
      this.content.refresh()
  }
}

export default observer(HomeScreen)
import { observable, observe } from 'mobx'
import Amplify, { Auth } from 'aws-amplify'

const AuthStatus = {
  SIGNED_OUT: 'signedOut',
  SIGNING_IN: 'signingIn',
  NEW_PASSWORD_REQUIRED: 'newPasswordRequired',
  CHANGING_PASSWORD: 'changingPassword',
  SIGNED_IN:  'signedIn',
}

class Credentials {
  @observable authStatus = 'signedOut'
  @observable username
  @observable password
  @observable jwt

  constructor() 
  {
    this.authStatus = AuthStatus.SIGNED_OUT
    for (const field of ['authStatus', 'username', 'password', 'jwt'])
    {
      this[field] = window.localStorage.getItem(field) 
      // intercept(this, field, change => console.log(field, this[field]))
      observe(this, field, change => window.localStorage.setItem(field, this[field]))
    }
    if (this.authStatus !== AuthStatus.SIGNED_IN)
      this.authStatus = AuthStatus.SIGNED_OUT

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: 'eu-west-2',
        userPoolId: 'eu-west-2_TTgl3yUBg',
        userPoolWebClientId: '74d5b064vsubtgrenf0b3t0jcr'
      },
      cookieStorage: {
        domain: window.location.hostname,
        path: '/',
        expires: 365,
        secure: false 
      },
    })

    // if (this.username && this.password)
    //   this.signIn()
  }

  async signIn()
  {
    this.authStatus = AuthStatus.SIGNING_IN

    try {      
      this.user = await Auth.signIn(this.username, this.password)
    }
    catch (e) {
      alert(e.message)
      this.authStatus = AuthStatus.SIGNED_OUT
      return
    }

    console.log('signin', this.user)

    if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED')
    {
      this.authStatus = AuthStatus.NEW_PASSWORD_REQUIRED
      return
    }

    this.completeSignIn()
  }

  async silentSignIn()
  {
    console.log('no longer authorised, signing in...')
    try {      
      this.user = await Auth.signIn(this.username, this.password)
    }
    catch (e) {
      alert(e.message)
      this.authStatus = AuthStatus.SIGNED_OUT
      return
    }
    this.completeSignIn()
  }

  signOut()
  {
    this.authStatus = AuthStatus.SIGNED_OUT
  }

  async changePassword(newPassword)
  {
    const prevStatus = this.authStatus

    this.authStatus = AuthStatus.CHANGING_PASSWORD

    try {
      this.user = await Auth.completeNewPassword(this.user, newPassword)
    } catch (e) {
      alert(e.message)
      this.authStatus = prevStatus
      return
    }
    
    console.log('changed pwd', this.user)
    this.password = newPassword

    this.completeSignIn()
  }

  completeSignIn()
  {
    this.jwt = this.user.signInUserSession.idToken.jwtToken
    this.authStatus = AuthStatus.SIGNED_IN
  }
}

export default Credentials
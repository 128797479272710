import React from 'react'
import { Typography, Paper, Box, Button, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import { observer } from 'mobx-react'
import Photo from '../store/Photo'
import store from '../store/Store'

function Heading(props)
{
  return <Typography variant='h5'>{props.children}</Typography>
}

export default observer(class PhotoForm extends React.Component
{
  capture()
  {
    const newRoute = store.route.slice()
    newRoute.push('capture')
    // window.location.href = '#/' + newRoute.join('/')
    window.location.href = '#/' + newRoute.join('/')
  }

  basePath()
  {
    return window.location.hash.split('/').slice(1, 3)

  }

  handleCancel()
  {
    window.history.back()
    // window.location.href = '#/' + this.basePath().join('/')
  }

  handleSave()
  {
    const id = store.route[3]
    const isEditing = id !== 'new'

    const basePath = this.basePath()
    const target = {
      grow: store.currentGrow,
      harvest: store.currentHarvest
    }[basePath[0]]
    
    if (isEditing)
      target.photos[parseInt(id)] = store.currentPhoto
    else
      target.photos.push(store.currentPhoto)

    // window.location.href = '#/' + this.basePath().join('/')
    window.history.back()


  }

  handleDelete()
  {
    if (!window.confirm('Are you sure you want to delete this photo?'))
      return      
    const basePath = this.basePath()
    const target = {
      grow: store.currentGrow,
      harvest: store.currentHarvest
    }[basePath[0]]
    const id = store.route[3]
    target.photos.splice(parseInt(id), 1)
    // window.location.href = '#/' + this.basePath().join('/')
    window.history.back()
  }

  render()
  {
    if (!store.currentPhoto)
      store.currentPhoto = new Photo() // !!!!

    const isEditing = store.route[3] !== 'new'
    const photo = store.currentPhoto
    
    const makeEditProps = (propName) => {
      return {value: photo[propName] || '', onChange: (e) => photo[propName] = e.target.value}
    }

    if (!photo)
      return <div/>

    return (
      <form  noValidate autoComplete="off">
        <div className="form">

          {/* {isEditing ? <section>
            <Heading>Captured</Heading>
            <Typography>15 April 2020, 7:03am</Typography>
          </section> : null} */}
          
          <div className="cols">

            <section>
              <Heading>Photo</Heading>
              {/* <Paper> */}
                <Paper elevation={3} style={{width: '100%'}}>
                  <Box p={1}/>
                  <Box style={{width: '100%'}} justifyContent="center" alignItems="center" display="flex">
                    <img src={photo && photo.image} alt="" style={{width: 'calc(100% - 32px)', borderRadius: 2}}/>
                  </Box>
                  <Box p={2} alignItems="center" justifyContent="center" display="flex">
                    <Button variant="contained" fullWidth color="secondary" size="large" startIcon={<CameraIcon/>} onClick={() => this.capture()}>Retake</Button>
                  </Box>
                </Paper>
            </section>

            <section>
              <Heading>Classification</Heading>
              <div>
                <div className="field">
                  <FormControl variant="outlined" style={{minWidth: 240}}>
                    <InputLabel id="imtype-label">Image</InputLabel>
                    <Select labelId="imtype-label" label="Image" {...makeEditProps('imageType')}>
                      <MenuItem><em>None</em></MenuItem>
                      <MenuItem value={'Plant'}>Plant</MenuItem>
                      <MenuItem value={'Leaf'}>Leaf</MenuItem>
                      <MenuItem value={'Fruit'}>Fruit</MenuItem>
                      <MenuItem value={'Grow medium'}>Grow medium</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="field">
                  <FormControl variant="outlined" style={{minWidth: 240}}>
                    <InputLabel id="imtype-label">Status</InputLabel>
                    <Select labelId="imtype-label" label="Status" {...makeEditProps('status')}>
                      <MenuItem><em>None</em></MenuItem>
                      <MenuItem value={'Healthy'}>Healthy</MenuItem>
                      <MenuItem value={'Diseased'}>Diseased</MenuItem>
                      <MenuItem value={'Damaged'}>Damaged</MenuItem>
                      <MenuItem value={'Deformed'}>Deformed</MenuItem>
                    </Select>
                  </FormControl>
                </div>

              </div>
            </section>
          </div>

          <div class="buttons">
            <Button variant="contained" color="primary" size="large" startIcon={<SaveIcon/>} onClick={() => this.handleSave()}>Save</Button>
            {isEditing ? <Button variant="contained" size="large" startIcon={<DeleteIcon/>} onClick={() => this.handleDelete()}>Delete</Button> : null}
            <Button size="large" variant="outlined" onClick={() => this.handleCancel()}>Cancel</Button>
          </div>

        </div>
      </form>
    )
  }
})
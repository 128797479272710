import React from 'react'
import { Typography, TextField, InputAdornment, Button, Select, MenuItem, InputLabel, FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import dataService from '../DataService'
import { observer } from 'mobx-react'
import { longDate } from '../util'
import store from '../store/Store'
import Harvest from '../store/Harvest'
import PhotoListInline from './PhotoListInline'

function Heading(props)
{
  return <Typography variant='h5'>{props.children}</Typography>
}

export default observer(class HarvestForm extends React.Component
{
  async componentDidMount()
  {
    const isEditing = store.route[1] !== 'new'
    if (!store.currentHarvest)
    {
      if (isEditing)
        store.currentHarvest = Harvest.fromJS(await dataService.db.get('harvest', parseInt(store.route[1])))
      else
        store.currentHarvest = new Harvest()
    }
  }

  async handleCancel()
  {
    window.history.back()
  }

  async handleDelete()
  {
    if (window.confirm('Are you sure you want to delete this observation?'))
      this.handleSave(true)
  }
  
  async handleSave(deleteRow = false)
  {
    const row = store.currentHarvest.toJS()
    row.syncState = 0
    row.deleted = deleteRow
    
    let id = row.id    
    if (id)
    {
      await dataService.db.put('harvest', row)
    }
    else    
    {
      delete row.id
      id = await dataService.db.put('harvest', row)
    }
    window.history.back()
  }  

  render()
  {
    if (!store.currentHarvest)
      return <div/>

    const isEditing = store.route[1] !== 'new'
    const row = store.currentHarvest   
    const makeEditProps = (parent, propName) => {
      return {value: parent[propName] || '', onChange: (e) => parent[propName] = e.target.value}
    }

    const makeDefectProps = (val) => {
      const defects = row.quality.defects
      return {
        checked: defects.indexOf(val) >= 0, 
        onChange: (e) => {
          const index = defects.indexOf(val)
          if (index >= 0)
            defects.splice(index, 1)
          if (e.target.checked)
            defects.push(val)
          console.log(defects)
        }}
    }

    return (
      <form  noValidate autoComplete="off">
        <div className="form">

          {isEditing ? <section>
            <Heading>Captured</Heading>
            <Typography>{longDate(row.created)}</Typography>
          </section> : null}

          <section>
            <Heading>Location</Heading>
            <div className="col3">
            <div className="field"><TextField label="Zone" variant="outlined" type="number" {...makeEditProps(row.location, 'zone')}/></div>
              <div className="field"><TextField label="Row" variant="outlined" type="number" {...makeEditProps(row.location, 'row')}/></div>
              <div className="field"><TextField label="Section" variant="outlined" type="number" {...makeEditProps(row.location, 'section')}/></div>
              <div className="field"><TextField label="Cluster" variant="outlined" type="number" {...makeEditProps(row.location, 'cluster')}/></div>
            </div>
          </section>

          <div className="cols">
            <section>
              <Heading>Yield</Heading>
              <div>
                <div className="field"><TextField label="Weight" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">kg</InputAdornment>}} {...makeEditProps(row.yield, 'weight')}/></div>
                <div className="field"><TextField label="Pollination" variant="outlined" type="number" InputProps={{endAdornment: <InputAdornment position="start">%</InputAdornment>}} {...makeEditProps(row.yield, 'pollination')}/></div>
                <div className="field"><TextField label="Fruit count" variant="outlined" type="number" {...makeEditProps(row.yield, 'fruitCount')}/></div>
              </div>
            </section>

            <section>
              <Heading>Quality</Heading>
              <div>
                <div className="field">
                  <FormControl variant="outlined" style={{minWidth: 120}}>
                    <InputLabel id="ripeness-label">Ripeness</InputLabel>
                    <Select labelId="ripeness-label" label="Ripeness" {...makeEditProps(row.quality, 'ripeness')}>
                      <MenuItem><em>None</em></MenuItem>
                      <MenuItem value={1}>Green (Stage 1)</MenuItem>
                      <MenuItem value={2}>Breakers (Stage 2)</MenuItem>
                      <MenuItem value={3}>Turning (Stage 3)</MenuItem>
                      <MenuItem value={4}>Pink (Stage 4)</MenuItem>
                      <MenuItem value={5}>Light red (Stage 5)</MenuItem>
                      <MenuItem value={6}>Red (Stage 6)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="field">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Fruit defects</FormLabel>
                  <div class="cols">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="growth-cracks" {...makeDefectProps('Growth cracks')}/>}
                      label="Growth cracks"
                    />
                    <FormControlLabel
                      control={<Checkbox name="deformation" {...makeDefectProps('Deformation')} />}
                      label="Deformation"
                    />
                    <FormControlLabel
                      control={<Checkbox name="blossom-end-rot" {...makeDefectProps('Blossom-end rot')}/>}
                      label="Blossom-end rot"
                    />
                    <FormControlLabel
                      control={<Checkbox name="blotchy" {...makeDefectProps('Blotchy')}/>}
                      label="Blotchy"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="pest-damage" {...makeDefectProps('Insect / pest damage')}/>}
                      label="Insect / pest damage"
                    />
                    <FormControlLabel
                      control={<Checkbox name="fruit-spots" {...makeDefectProps('Fruit spots')}/>}
                      label="Fruit spots"
                    />
                    <FormControlLabel
                      control={<Checkbox name="bruising" {...makeDefectProps('Bruising')}/>}
                      label="Bruising"
                    />

                  </FormGroup>
                  </div>
                </FormControl>
                </div>
              </div>
            </section>

          </div>

          <section>
            <Heading>Photos</Heading>
            <div>
              <PhotoListInline target={row}/>
            </div>
          </section>

          <div className="buttons">
            <Button variant="contained" color="primary" size="large" startIcon={<SaveIcon/>} onClick={() => this.handleSave()}>Save</Button>
            {isEditing ? <Button variant="contained" size="large" startIcon={<DeleteIcon/>} onClick={() => this.handleDelete()}>Delete</Button> : null}
            <Button size="large" variant="outlined" onClick={() => window.history.back()}>Cancel</Button>
          </div>

        </div>
      </form>
    )
  }
})
import { openDB } from 'idb'

class DataService
{
  ready = false

  constructor()
  {
    if (!('indexedDB' in window))
      alert('No IndexedDB support. Can\'t init DataService.')      
  }

  async init()
  {
    this.db = await openDB('cortex-mia', 8, {
      upgrade(db, oldVersion, newVersion, transaction) {
        if (!db.objectStoreNames.contains('grow'))
          db.createObjectStore('grow', {autoIncrement: true, keyPath: 'id'})
        if (!db.objectStoreNames.contains('harvest'))
          db.createObjectStore('harvest', {autoIncrement: true, keyPath: 'id'})
      }
    })

    this.ready = true
    
    // const grows = await this.db.getAll('grow')
    // console.log(grows)

  }
}

const dataService = new DataService()
export default dataService